import { http } from '@/http/axios.js'
// 详情
export function getAdDetailAPI(pageCode) {
  return http({
    url: '/admin/oprt/ad/detail',
    method: 'get',
    params: { pageCode }
  })
}
// 保存
export function saveAdAPI(data) {
  return http({
    url: '/admin/oprt/ad/save',
    method: 'post',
    data
  })
}
