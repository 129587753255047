<template>
  <div>
    <el-dialog title="选择链接" :visible.sync="dialogVisible" width="width" @closed="drawer = false">
      <div style="position: relative;">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <span class="collapse-title" slot="title">基础页面</span>
            <div class="select-link">
              <el-button @click="handleLinkClick" type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
              <el-button type="" size="mini">商城首页</el-button>
              <el-button type="" size="mini">分类页</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <span class="collapse-title" slot="title">商城页面</span>
            <div class="select-link">
              <el-button type="" size="mini">商品列表页</el-button>
              <el-button type="" size="mini">领券中心</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <span class="collapse-title" slot="title">其他页面</span>
            <div class="select-link">
              <el-button @click="handleLinkClick" type="" size="mini">咨询详情</el-button>
              <el-button type="" size="mini">领券中心</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-drawer size="35%" class="drawer-container" :visible.sync="drawer" z-index="-1" style="position: absolute;" width="100%">
          <span slot="title">抽屉标题xxx</span>
          <el-divider></el-divider>
          <div class="link-id">
            <el-form ref="form" :model="idFormData" label-width="80px">
              <el-form-item label="分类ID:">
                <el-input v-model="model" size="small"></el-input>
                <span class="tips">商品管理->商品分类</span>
              </el-form-item>
              <el-form-item label="关键词:">
                <el-input v-model="model" size="small"></el-input>
                <span class="tips">商品管理->商品分类</span>
              </el-form-item>
            </el-form>
          </div>
        </el-drawer>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="small">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'SelectLink',

  data() {
    return {
      direction: 'rtl', //抽屉打开方向
      drawer: false, //控制抽屉
      dialogVisible: false, //控制弹窗
      activeNames: ['1', '2', '3'], //collapse默认展开项
      idFormData: {}
    }
  },

  mounted() {},

  methods: {
    handleLinkClick() {
      this.drawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.el-collapse /deep/ {
  .el-collapse-item__content {
    padding-bottom: 0;
  }
  .el-collapse-item__header,
  .el-collapse-item__wrap {
    background-color: #f0f2f5;
    padding-left: 10px;
    padding-right: 10px;
    //  border-bottom: 1px solid #ccc;
  }
  .el-collapse-item__header {
    font-size: 15px;
    font-weight: 600;
  }
  .collapse-title {
    flex: 1 0 90%;
    order: 1;
  }

  .select-link {
    .el-button {
      margin: 10px 10px 10px 0;
    }
  }
}
.drawer-container /deep/ {
  .el-drawer__header {
    margin-bottom: 0;
    padding-top: 0;
  }
  .el-divider {
    margin: 10px 0;
  }
}
.el-form {
  .el-form-item {
    margin-bottom: 0;
    .tips {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
